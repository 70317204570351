import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { store } from "./app/store";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CheckoutScreen } from "./app/screens/Checkout/CheckoutScreen";
import { OrdersHistory } from "./app/screens/orders/OrdersHistory";
import { AppNavbar } from "./Partials/Navbar";
import { EditProfile } from "./app/screens/profile/EditProfile";
import { FullScreenLoader } from "./app/components/Loader/FullScreenLoader";
import { ReduxLoader } from "./app/components/Loader/ReduxLoader";

const persistor = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ToastContainer />
        <ReduxLoader />
        <Router>
          <AppNavbar />
          <Switch>
            <Route path="/checkout">
              <CheckoutScreen />
            </Route>
            <Route path="/orders">
              <OrdersHistory />
            </Route>
            <Route path="/edit-profile">
              <EditProfile />
            </Route>
            <Route path="/">
              <App />
            </Route>
          </Switch>
        </Router>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
