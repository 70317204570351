import { AxiosError, AxiosResponse } from 'axios';
import React, { useState } from 'react'
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { appAxios } from '../../../network/axios';
// import { PaymentCardItem } from '../../components/Payment/PaymentCardItem';
import { useAppDispatch, useAppSelector } from '../../hooks/redux.hook';
import { CartActions, selectCartData } from '../../slices/cartData/cartData.slice';
import { selectSiteData } from '../../slices/siteData/siteData.slice';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CardForm from '../../components/Payment/CardForm';
import { AppNavbar } from '../../../Partials/Navbar';

const stripePromise = loadStripe("pk_test_51HCq8iBz2nvmaKY1TuIoB6fCn8e77u67CfqTuHNNJF4V7pSeZrXFW3RcVxRMnCv7puVX1bTVzuqywhltFTtes67500PBF8Evrc");

export interface IPaymentMethod {
    billing_details: {
        name: string;
    };
    id: number;
    card: {
        brand: string;
        last4: number;
        exp_month: number;
        exp_year: number;
    }
}

export const CheckoutScreen = () => {


    const [cardLoadingState, setCardLoadingState] = useState(false);
    const [defaultCardState, setDefaultCardState] = useState<IPaymentMethod>();
    const [loadingState, setLoadingState] = useState(false);

    const dispatch = useAppDispatch();
    const cartState = useAppSelector(selectCartData)
    const siteState = useAppSelector(selectSiteData)


    const createOrder = () => {
        setLoadingState(true);

        // let coupon = couponState;
        // if (!couponAppliedState) {
        //     coupon = '';
        // }
        let coupon = "";

        appAxios.post('/user/create-order', {
            businessId: siteState.businessData?.id,
            menus: cartState.cartItems,
            // coupon: couponState,
        }).then((res: AxiosResponse) => {
            if (res.data && res.data.success) {
                // Alert.alert("Order Successfully placed");


                if (res.data.payment_success) {

                    // if (cartState.restaurantDetail) {
                    dispatch(CartActions.emptyCart());
                    toast.success("Order Successfully placed")

                    // if (res.data.data.autoAccept) {

                    //     let remainingSeconds = moment.tz(res.data.data.delivery_time, BACKEND_TIMEZONE).diff(moment.tz(BACKEND_TIMEZONE), 'seconds')

                    //     navigation.push("PreparingScreen", {
                    //         orderId: res.data.data.orderId,
                    //         remainingSeconds: remainingSeconds,
                    //         deliveryTime: res.data.data.delivery_time
                    //     })
                    // } else {
                    //     navigation.push("WaitingScreen", {
                    //         orderId: res.data.data.orderId
                    //     })
                    // }
                    // }
                }

                else {
                    toast.error("There was some error completing the payment. Please try again. If the problem persists, change your card and try again.")
                }

            } else {
                if (res.data && res.data.data.error) {
                    toast.error(res.data.data.error)
                    setLoadingState(false);
                }
            }
        }).catch((err: AxiosError) => {
            setLoadingState(false);
            toast.error(err.message)
        }).finally(() => {

        })
    }


    return (
        <>
            {/* <AppNavbar /> */}

            <div className="container">
                <div className="row my-5">
                    <div className="col-12">
                        <Elements stripe={stripePromise}>
                            {/* <ElementDemos demos={demos} /> */}
                            <CardForm />
                        </Elements>
                    </div>
                </div>

                {/* <button className="btn btn-primary"
                // disabled={true}
                disabled={(!defaultCardState) || (loadingState || cartState.cartItems.length < 1)}
                // disabled={!defaultCardState && (loadingState || cartState.cartItems.length < 1)}
                style={{
                    justifyContent: 'center'
                }}
                onClick={() => {
                    createOrder();
                }}
            >
                Proceed to payment {loadingState && <Spinner animation="border" />}
            </button> */}

            </div>
        </>
    )
}
