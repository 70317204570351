import React, {useState, useEffect} from 'react'

interface IIncDecProps{
    quantity: number;
    onChange: (qty: number) => void;
}

export const IncrementDecrementComponent: React.FC<IIncDecProps> = (props) => {

    const [qtyState, setQtyState] = useState(props.quantity)

    const incDec = (isIncrement: boolean) => {
        setQtyState(qty =>  isIncrement ? (qty +1) : (qty - 1))
    }

    useEffect(() => {
        props.onChange(qtyState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [qtyState])

    return (
        <div className="input-group">
            <button onClick={() => incDec(false)} className="btn btn-outline-secondary" type="button" id="button-addon1"><i className="fas fa-minus"></i></button>
            <input type="text" style={{
                maxWidth: 50
            }} className="form-control" placeholder="" value={qtyState} aria-label="Example text with button addon" aria-describedby="button-addon1" />
            <button onClick={() => incDec(true)} className="btn btn-outline-secondary" type="button" id="button-addon1">
                <i className="fas fa-plus"></i>
            </button>
        </div>
    )
}
