import React from 'react'
import { ListGroup } from 'react-bootstrap';
import { MENU_FEATURED_URL } from '../../../Env';
import { IMenuListItem } from '../../../types/menuList'
import dishPlaceholder from "../../assets/dish-placeholder.png";

export const MenuItem: React.FC<{
    menuDetail: IMenuListItem;
    onAdd: Function;
}> = (props) => {
  return (
      <>
      
      {props.menuDetail.is_active && (
          <div
            className="col-lg-4 col-md-4 col-6 my-2 cursor-pointer"
            key={props.menuDetail.id}
            onClick={() => props.onAdd(props.menuDetail.id)}
          >
            <ListGroup.Item
              className="p-3"
              // onClick={() => handleAddClick(props.menuDetail.id)}
            >
              {/* <div className=" container-fluid"> */}
              <div className="row ">
                <div className="col-md-8">
                  <div className="menu-text-price ">
                    <div>
                      <p className="mb-1">{props.menuDetail.title}</p>
                    </div>
                    <div className="menu-price">
                      CAD {props.menuDetail.price.toFixed(2)}
                    </div>
                    {/* <button className="btn btn-primary btn-sm" onClick={() => handleAddClick(props.menuDetail.id)}>
                                                          Add
                                                      </button> */}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="menu-item-img-container">
                    {
                      // props.menuDetail.featured_image && (
                      <img
                        src={
                          props.menuDetail.featured_image
                            ? `${MENU_FEATURED_URL}/${props.menuDetail.featured_image}`
                            : `${dishPlaceholder}`
                        }
                        alt={props.menuDetail.title}
                        className="menu-item-img"
                      />
                      // )
                    }
                  </div>
                </div>
              </div>
              {/* </div>  */}
            </ListGroup.Item>
          </div>
        )}
      </>
  )
}
