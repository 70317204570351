import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { appAxios } from "../../../network/axios";
import moment from "moment";
export const OrdersHistory: React.FC<{}> = (props) => {
  const [ordersState, setOrdersState] = useState<any>([]);

  const [loadingState, setLoadingState] = useState(false);

  useEffect(() => {
    setLoadingState(true);
    appAxios
      .get("/user/get-all-orders")
      .then((res: any) => {
        setOrdersState(res.data.data);
      })
      .catch((err: any) => {
        // console.log(err);
        let errorMessage = err.response?.data?.message;
        if (!errorMessage) {
          errorMessage = err.message;
        }
        toast.error(errorMessage);
      })
      .finally(() => {
        setLoadingState(false);
      });
  }, []);

  return (
    <div
      style={{
        marginTop: "150px",
      }}
      className="container"
    >
      <div className="row">
        <div className="col-12">
          {ordersState.length === 0 && (
            <div className="text-center">
              <h3>No Orders Found</h3>
            </div>
          )}
          {ordersState.map((order: any, index: number) => {
            return (
              <div className="card mt-3" key={index}>
                <div className="card-body shadow">
                  {/* <pre>{JSON.stringify(order, null, 2)}</pre>; */}
                  <div className="row">
                    <div className="col-md-6">
                      <h5 className="text-primary">#{order.id}</h5>
                      {order.order_menus.map((omenu: any) => {
                        // return <pre>{JSON.stringify(omenu, null, 2)}</pre>;
                        return (
                          <p>
                            <i className="fas fa-chevron-right text-primary me-1"></i>
                            {omenu.menu.title}
                          </p>
                        );
                      })}
                    </div>
                    <div className="col-md-6 text-end">
                      <div className="mb-2">
                        <small>{moment(order.created_at).format("lll")}</small>
                      </div>
                      <h5>Total: ${order.total_charges}</h5>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
