import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { appAxios } from "../../../network/axios";
import { useAppDispatch } from "../../hooks/redux.hook";
import { CommonActions } from "../../slices/common/common.slice";

export const EditProfile: React.FC<{}> = () => {
  const [profileState, setProfileState] = useState<{
    name?: string;
    phone?: string;
    email?: string;
  }>();

  const [errorState, setErrorState] = useState<{
    name?: string;
    email?: string;
    phone?: string;
  }>();

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(CommonActions.setLoading(true));
    appAxios
      .get("/user/detail")
      .then((res: any) => {
        setProfileState(res.data.user);
        console.log(res);
      })
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(CommonActions.setLoading(false));
      });
  }, []);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // if (e.target.value) {
    setProfileState({
      ...profileState,
      [e.target.name]: e.target.value,
    });
    // }
  };

  const onSubmit = async (e: any) => {
    dispatch(CommonActions.setLoading(true));

    if (e.preventDefault) e.preventDefault();
    try {
      const registerResponse = await appAxios.post(
        "/user/update-user-details",
        profileState
      );
      if (registerResponse.data?.success) {
        toast.success("Profile updated successfully.");
      } else {
        throw new Error(registerResponse.data.errorMessage);
      }
    } catch (error: any) {
      if (error.response.data.errors) {
        setErrorState(error.response.data.errors);
      }
      toast.error(error.response?.data?.message || error.message);
    }
    dispatch(CommonActions.setLoading(false));
  };

  return (
    <div
      style={{
        marginTop: "150px",
      }}
      className="container"
    >
      <h3>Edit Profile</h3>
      {/* <pre>{JSON.stringify(profileState, null, 2)}</pre> */}
      <form onSubmit={onSubmit}>
        <div className="form-group">
          <label htmlFor="">Name</label>
          <input
            required
            name="name"
            type="text"
            value={profileState?.name}
            className="form-control"
            onChange={onInputChange}
          />
          {errorState && errorState.name && (
            <div className="text-danger">{errorState.name}</div>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="">Email</label>
          <input
            required
            name="email"
            type="email"
            value={profileState?.email}
            className="form-control"
            onChange={onInputChange}
          />
          {errorState && errorState.email && (
            <div className="text-danger">{errorState.email}</div>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="">Phone</label>
          <input
            name="phone"
            required
            type="text"
            value={profileState?.phone}
            className="form-control"
            onChange={onInputChange}
          />
          {errorState && errorState.phone && (
            <div className="text-danger">{errorState.phone}</div>
          )}
        </div>

        <button className="btn btn-primary mt-3">Submit</button>
      </form>
    </div>
  );
};
