import React, { useState } from "react";
import { Nav, Navbar, Container, NavDropdown } from "react-bootstrap";
import { selectSiteData } from "../app/slices/siteData/siteData.slice";
import { useAppDispatch, useAppSelector } from "../app/hooks/redux.hook";
import { RESTAURANT_FEATURED_URL } from "../Env";
import { RegisterPopup } from "../app/components/Auth/RegisterPopup";
import { LoginPopup } from "../app/components/Auth/LoginPopup";
import { authActions, logout } from "../app/slices/auth/auth.slice";
import { ForgotPasswordPopup } from "../app/components/Auth/ForgotPassword";
import { Link } from "react-router-dom";
export const AppNavbar = () => {
  const siteData = useAppSelector(selectSiteData);

  const [isRegisterPopupOpen, setIsRegisterPopupOpen] = useState(false);
  const [isLoginPopupOpen, setIsLoginPopupOpen] = useState(false);

  const authData = useAppSelector((state) => state.authData);

  const dispatch = useAppDispatch();
  return (
    <>
      {isRegisterPopupOpen && (
        <RegisterPopup onClose={() => setIsRegisterPopupOpen(false)} />
      )}
      {isLoginPopupOpen && (
        <LoginPopup onClose={() => setIsLoginPopupOpen(false)} />
      )}
      {authData.isForgotPopupOpen && (
        <ForgotPasswordPopup
          onClose={() => {
            dispatch(authActions.setForgotPopupOpen(false));
          }}
        />
      )}

      <div>
        <div className="">
          <div className="nav-topbar">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-light text-center">
                  Get 10% off on your next takeout use Code "safusion"
                </div>
              </div>
            </div>
          </div>
          <Navbar bg="light" expand="lg">
            <Container>
              <Navbar.Brand href="/">
                {!siteData?.loading &&
                  (siteData?.businessData?.featured_img ? (
                    <img
                      src={`${RESTAURANT_FEATURED_URL}/${siteData?.businessData?.featured_img}`}
                      alt={siteData?.businessData?.name}
                      className="img-fluid logo-img"
                    />
                  ) : (
                    siteData?.businessData?.name || "Food Ordering"
                  ))}
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto">
                  {authData.isLoggedIn ? (
                    <>
                      <NavDropdown
                        title={authData.userName}
                        id="basic-nav-dropdown"
                      >
                        <NavDropdown.Item to="/orders" as={Link}>
                          Manage Orders
                        </NavDropdown.Item>
                        <NavDropdown.Item  to="/edit-profile" as={Link}>
                          Edit Profile
                        </NavDropdown.Item>
                        {/* <NavDropdown.Item >
                          Change Password
                        </NavDropdown.Item> */}
                      </NavDropdown>
                      <Nav.Link
                        onClick={() => {
                          dispatch(logout());
                        }}
                      >
                        Logout
                      </Nav.Link>
                    </>
                  ) : (
                    <>
                      <Nav.Link
                        onClick={() => {
                          setIsLoginPopupOpen(true);
                        }}
                      >
                        Login
                      </Nav.Link>
                      <Nav.Link
                        onClick={() => {
                          setIsRegisterPopupOpen(true);
                        }}
                      >
                        Signup
                      </Nav.Link>
                    </>
                  )}
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </div>
    </>
  );
};
