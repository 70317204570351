import React, { useEffect, useState } from 'react'
import { Modal, Button, ListGroup, ListGroupItem, Form } from 'react-bootstrap'
import { IAddonCategory } from '../../../types/menuList'
import { useAppDispatch, useAppSelector } from '../../hooks/redux.hook'
import { ICartItem } from '../../slices/cartData/cart'
import { CartActions, selectCartData } from '../../slices/cartData/cartData.slice'
import { IncrementDecrementComponent } from '../IncrementDecrementComponent'

export const MenuAddonModal: React.FC<{
    onClose: () => void;
    cartItemIndex?: number;
    selectedMenuId: number | null;
    addonsList: Array<IAddonCategory>;
}> = (props) => {

    const [selectedAddonState, setSelectedAddonState] = useState<Array<number>>([])
    const [cartQtyState, setCartQtyState] = useState<number>(1)
    const [addonRequiredErrorsState, setAddonRequiredErrorsState] = useState<Array<number>>([])

    const cartState = useAppSelector(selectCartData)

    const [specialInstructionsState, setSpecialInstructionsState] = useState('');

    const toggleSelectAddon = (addonId: number) => {

        let isAddonSelected = selectedAddonState.includes(addonId);

        if (isAddonSelected) {
            setSelectedAddonState(selectedAddonState.filter(addon => {
                return addon !== addonId;
            }))
        }
        else {
            setSelectedAddonState([
                ...selectedAddonState,
                addonId,
            ])
        }
    }


    useEffect(() => {
        // alert("modl")
        if (props.cartItemIndex !== undefined) {
            setSelectedAddonState(cartState.cartItems[props.cartItemIndex].addons);
            setSpecialInstructionsState(cartState.cartItems[props.cartItemIndex].specialInstruction);
            setCartQtyState(cartState.cartItems[props.cartItemIndex].quantity);
        }
        return () => {
            // alert("modl closed")

            // cleanup
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.cartItemIndex])

    const handleRadioChange = (addonId: number, categoryId: number) => {
        let addonCategoryIndex;

        if (props.addonsList) {

            addonCategoryIndex = props.addonsList.findIndex(addonCat => addonCat.id === categoryId);

            let selectedAddons = [...selectedAddonState];

            if (addonCategoryIndex >= 0) {

                // removing all addons from cart from this category as it is single selection
                props.addonsList[addonCategoryIndex].addons.forEach(addon => {
                    if (selectedAddons.includes(addon.id)) {
                        selectedAddons = selectedAddons.filter(fAddon => fAddon !== addon.id);
                    }
                })
            }

            // adding just one to the end of cart
            setSelectedAddonState([
                ...selectedAddons,
                addonId,
            ]);

        }
    }

    const afterCloseWindow = () => {
        setCartQtyState(1);
        setSelectedAddonState([]);
        setAddonRequiredErrorsState([]);
        setSpecialInstructionsState('');

        if (props.onClose) {
            props.onClose();
        }
    }


    const dispatch = useAppDispatch();

    const submitForm = (cartDetails: ICartItem) => {

        setAddonRequiredErrorsState([]);
        let addonRequiredErrors: Array<number> = [];
        props.addonsList?.forEach(addonCat => {
            // Checking if all required addons are selected or not
            if (addonCat.is_required) {
                let addonCatSelectedIteration = 0;

                addonCat.addons.forEach(addon => {
                    if (cartDetails.addons.includes(addon.id)) {
                        addonCatSelectedIteration++;
                    }
                })
                if (addonCatSelectedIteration === 0) {
                    //user has not selected even one item
                    addonRequiredErrors.push(addonCat.id);
                }
            }
            // if (addonRequiredErrors.length === 0)
        })
        setAddonRequiredErrorsState(addonRequiredErrors);

        if (addonRequiredErrors.length === 0) {

            if (props.cartItemIndex !== undefined && props.cartItemIndex > -1) {
                dispatch(CartActions.updateCartItems({
                    cartDetails: cartDetails,
                    cartIndex: props.cartItemIndex
                }))
            } else {
                dispatch(CartActions.addItemToCart(cartDetails))
            }

            afterCloseWindow();
        }
    }

    return (
        <div>
            <Modal
                {...props}
                show={true}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton onHide={props.onClose}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Select Addons
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{
                        padding: 10,
                        flex: 1
                    }}>

                        <div style={{
                            marginBottom: 20,
                        }} className="form-group">
                            <label className="label mb-2">
                                Special Instructions:
                            </label>
                            <textarea
                                onChange={(e) => setSpecialInstructionsState(e.target.value)}
                                value={specialInstructionsState}
                                rows={4}
                                className="form-control"
                                placeholder="Write special instructions here..."
                            />
                        </div>


                        {props.addonsList && props.addonsList.map((addonCat, index) => {
                            if (addonCat.addons.length === 0) {
                                return null;
                            }

                            if (addonCat.multiple_choice) {

                                return (
                                    <div key={`${addonCat.id.toString()} + ${index.toString()}`}>
                                        <p>
                                            {addonCat.title}
                                            {
                                                addonCat.is_required &&
                                                <span style={{
                                                    color: 'red'
                                                }}>*</span>
                                            }
                                        </p>
                                        {
                                            addonRequiredErrorsState.includes(addonCat.id) &&
                                            <p style={{ color: 'red' }}>
                                                Please select atleast one
                                            </p>
                                        }
                                        <ListGroup>
                                            {addonCat.addons.map((addon, i) => {

                                                return (
                                                    <ListGroupItem key={i.toString()} onClick={() => {
                                                        toggleSelectAddon(addon.id);
                                                    }}>
                                                        <div style={{
                                                            flexDirection: 'row'
                                                        }}>


                                                            <Form.Check
                                                                inline
                                                                label={`${addon.title} (${addon.price})`}
                                                                // name="group1"
                                                                type="checkbox"
                                                                checked={selectedAddonState.includes(addon.id)}
                                                                // id={`inline-radio-1`}
                                                                onClick={() => {
                                                                    toggleSelectAddon(addon.id);
                                                                }}
                                                            // onClick={() => handleRadioChange(obj.value, obj.addon_category_id)}
                                                            />


                                                            {/* <CheckBox color={PrimaryColor} onPressOut={() => {
                                                                toggleSelectAddon(addon.id);
                                                            }} checked={selectedAddonState.includes(addon.id)} /> */}
                                                            {/* <div onClick={() => {
                                                                toggleSelectAddon(addon.id);
                                                            }}>
                                                                <p style={{
                                                                    marginLeft: 30
                                                                }}>
                                                                    {addon.title} (${addon.price})
                                                                </p>
                                                            </div> */}
                                                        </div>

                                                    </ListGroupItem>
                                                )
                                            })}
                                        </ListGroup>
                                    </div>
                                )
                            } else {

                                const radioMappedAddons = addonCat.addons.map(addon => {

                                    // let addonLabel = addon.title + (addon.price > 0) ? '($' + addon.price + ')' : null;
                                    let addonLabel = addon.title;
                                    if (addon.price > 0) {
                                        addonLabel += ' ($' + addon.price + ')';
                                    }
                                    return {
                                        ...addon,
                                        label: addonLabel,
                                        value: addon.id
                                    }
                                })

                                return (
                                    <div key={`${addonCat.id.toString()} + ${index.toString()}`}>
                                        <p>
                                            {addonCat.title}
                                            {
                                                addonCat.is_required &&
                                                <span style={{
                                                    color: 'red'
                                                }}>*</span>
                                            }
                                        </p>
                                        {
                                            addonRequiredErrorsState.includes(addonCat.id) &&
                                            <p style={{ color: 'red' }}>
                                                Plese select any one addon.
                                            </p>
                                        }

                                        <Form>

                                            {radioMappedAddons.map((obj, i) => (
                                                <ListGroupItem key={i.toString()} style={{
                                                    paddingLeft: 15
                                                }} onClick={() => {
                                                    handleRadioChange(obj.value, obj.addon_category_id)
                                                }}>

                                                    <div className="mb-3">
                                                        <Form.Check
                                                            inline
                                                            label={`${obj.title} ($${obj.price})`}
                                                            name="group1"
                                                            type="radio"
                                                            checked={selectedAddonState.includes(obj.id)}
                                                            onClick={() => handleRadioChange(obj.value, obj.addon_category_id)}
                                                        />
                                                    </div>
                                                </ListGroupItem>
                                            ))
                                            }
                                        </Form>
                                        {/* <RadioForm
                                            formHorizontal={false}
                                            buttonColor={'red'}
                                            animation={false}

                                            style={{
                                                marginVertical: 15,
                                                // marginLeft: 25
                                            }}
                                        >
                                            {radioMappedAddons.map((obj, i) => (
                                                <ListGroupItem key={i.toString()} style={{
                                                    paddingLeft: 15
                                                }} onClick={() => {
                                                    handleRadioChange(obj.value, obj.addon_category_id)
                                                }}>

                                                    <RadioButton key={i} wrapStyle={{
                                                        marginLeft: 0
                                                    }} >
                                                        <RadioButtonInput
                                                            obj={obj}
                                                            index={i}
                                                            isSelected={selectedAddonState.includes(obj.id)}
                                                            onPress={() => handleRadioChange(obj.value, obj.addon_category_id)}
                                                            borderWidth={1}
                                                            buttonInnerColor={PrimaryColor}
                                                            buttonOuterColor={selectedAddonState.includes(obj.id) ? PrimaryColor : '#333'}
                                                            buttonSize={12}
                                                            buttonOuterSize={20}
                                                            buttonStyle={{}}
                                                        // buttonWrapStyle={{ marginLeft: -10 }}
                                                        />
                                                        <RadioButtonLabel
                                                            obj={obj}
                                                            index={i}

                                                            // labelHorizontal={true}
                                                            onPress={() => handleRadioChange(obj.value, obj.addon_category_id)}
                                                            labelStyle={{ fontSize: 16, color: '#333' }}
                                                            labelWrapStyle={{
                                                                marginLeft: 20
                                                            }}
                                                        />
                                                    </RadioButton>
                                                </ListGroupItem>
                                            ))
                                            }
                                        </RadioForm> */}
                                    </div>
                                )
                            }

                        })}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex w-100 justify-content-between">
                        <IncrementDecrementComponent
                            onChange={(qty) => { setCartQtyState(qty) }}
                            quantity={1}
                        />
                        <Button onClick={() => {
                            if (props.selectedMenuId) {

                                const cartDetails: ICartItem = {
                                    menuId: props.selectedMenuId,
                                    addons: selectedAddonState,
                                    quantity: cartQtyState,
                                    specialInstruction: specialInstructionsState,
                                }

                                submitForm(cartDetails);

                            }
                        }}>Add</Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
