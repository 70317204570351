import React, { useEffect } from 'react'
import { Modal, Button, ListGroup, ListGroupItem } from 'react-bootstrap'
import { useHistory } from 'react-router'

import { useAppDispatch, useAppSelector } from '../../hooks/redux.hook'
import { CartActions, selectCartData } from '../../slices/cartData/cartData.slice'

export const CartModal: React.FC<{
    onClose: () => void;
}> = (props) => {


    const cartState = useAppSelector(selectCartData)

    const deleteCartItem = (cartIndex: number) => {
        dispatch(CartActions.removeItemFromCart(cartIndex));
    }

    const history = useHistory();

    const dispatch = useAppDispatch();

    useEffect(() => {

    }, [])

    return (
        <div>
            <Modal
                {...props}
                show={true}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton onHide={() => dispatch(CartActions.toggleOpenCart())}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Cart Items
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ListGroup>
                        {
                            cartState.cartItems.length === 0 && (
                                <ListGroupItem>
                                    <p>No items in cart.</p>
                                </ListGroupItem>
                            )
                        }
                        {cartState.cartItems.map((cart, index) => {
                            let menuTitle: string = "";
                            let menuDescription;
                            let menuAmount = 0;
                            cartState.selectedBusinessMenu.forEach(menuCat => {
                                menuCat?.menu_list.forEach(menu => {
                                    if (menu.id === cart.menuId) {
                                        // alert(menu.title)
                                        menuTitle = menu.title;
                                        menuDescription = menu.description;
                                        menuAmount = menu.price;
                                        menu.addon_categories.forEach(addonCat => {
                                            addonCat.addons.forEach(addon => {
                                                if (cart.addons.includes(addon.id)) {
                                                    menuAmount += addon.price;
                                                }
                                            })
                                        })
                                        menuAmount *= cart.quantity;
                                        return;
                                    }
                                })
                                if (menuTitle) {
                                    return;
                                }
                            })
                            return (
                                <ListGroupItem key={index} className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <p className="mb-0">{menuTitle} x {cart.quantity}</p>
                                        <p className="mb-0 text-muted text-small"> {menuDescription}</p>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <div className="me-2">
                                            ${(menuAmount).toFixed(2)}
                                        </div>
                                        <button className="btn btn-sm btn-danger" onClick={() => deleteCartItem(index)}>
                                            <i className="far fa-trash-alt"></i>
                                        </button>
                                    </div>
                                </ListGroupItem>
                            )
                        })}
                    </ListGroup>
                </Modal.Body>
                {
                    cartState.cartItems.length > 0 && (
                        <Modal.Footer>
                            <Button onClick={() => {
                                // if (props.selectedMenuId) {
                                    history.push("/checkout")
                                // }
                            }}>Checkout</Button>
                        </Modal.Footer>
                    )
                }
            </Modal>
        </div>
    )
}
