import React, { useEffect } from 'react';
import { getRestaurantMenuAsync, getSiteDataAsync, selectSiteData } from './app/slices/siteData/siteData.slice';
import { useAppDispatch, useAppSelector } from './app/hooks/redux.hook';
import { RestaurantMenuScreen } from './app/screens/Restaurant/RestaurantMenuScreen';
import './App.css';
import { selectCommonData } from './app/slices/common/common.slice';


const App: React.FC = () => {
  const businessData = useAppSelector(selectSiteData);
  const commonData = useAppSelector(selectCommonData);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSiteDataAsync())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(businessData.businessData?.id){
      dispatch(getRestaurantMenuAsync(businessData.businessData.id))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessData.businessData])

  return (
    <div>
     
      <RestaurantMenuScreen />
    </div>
  );
}

export default App;
