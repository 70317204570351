import { ICartItem } from '../app/slices/cartData/cart';
import { TMenuList } from '../types/menuList';

export const calculateAmount = (allMenu: Array<TMenuList>,cartItems: Array<ICartItem>) => {

    let amount = 0;

    allMenu.forEach(menuCat => {
        menuCat.menu_list.forEach(menu => {
            cartItems.forEach(ci => {
                if(ci.menuId === menu.id){
                    let menuAmount = 0;
                    
                    menuAmount += menu.price;

                    menu.addon_categories.forEach(addonCat => {
                        addonCat.addons.forEach(addon => {
                            if(ci.addons.includes(addon.id)){
                                menuAmount += addon.price;   
                            }
                        })
                    })

                    amount +=  menuAmount * ci.quantity;
                    return;
                }
            })
        })
    })
    
    return amount;
    // return parseFloat((amount).toFixed(2));
}
