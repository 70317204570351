import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import siteDataReducer from './slices/siteData/siteData.slice';
import cartDataReducer from './slices/cartData/cartData.slice';
import authDataReducer from './slices/auth/auth.slice';
import commonReducer from './slices/common/common.slice';
import { FLUSH, PAUSE, persistReducer, REHYDRATE, PERSIST, PURGE, REGISTER } from 'redux-persist'

const reducers = combineReducers({
  siteData: siteDataReducer,
  cartData: cartDataReducer,
  authData: authDataReducer,
  common: commonReducer
});

const persistConfig = {
  key: 'root',
  storage
};
const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;