import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TMenuList } from '../../../types/menuList';
import { RootState } from '../../store';
import { ICartItem, ICartState } from './cart';



const initialState: ICartState = {
    cartItems: [],
    selectedBusinessMenu: [],
    isOpen: false
};


export const cartDataSlice = createSlice({
  name: 'cartData',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    getCartCount(state) {
        // state.
        // state.cartItems.find(item => );
    },
    addItemToCart(state, action: PayloadAction<ICartItem>) {
        // let cartIndex = state.cartItems.findIndex(item => action.payload.menuId === item.menuId);
        // if (cartIndex > -1) {
        //     state.cartItems[cartIndex] = action.payload;
        // } else {
        //     state.cartItems.push(action.payload)
        // }
        state.cartItems.push(action.payload);
    
        // amount += action.payload.
    },

    updateCartItems(state, action: PayloadAction<{
        cartIndex: number;
        cartDetails: ICartItem;
    }>){
        state.cartItems[action.payload.cartIndex] = action.payload.cartDetails;
    },

    setSelectedBusinessMenuWithAddons(state, action: PayloadAction<Array<TMenuList>>) {
        state.selectedBusinessMenu = action.payload;
    },

    incrementCartQty(state, action: PayloadAction<number>) {
        let cartItemIndex = getCartItemIndexById(state, action.payload);
        // console.log(state.cartItems);

        // let cartItemIndex = state.cartItems.findIndex(ci => {
        //     return ci.menuId === action.payload
        // });
        console.log(cartItemIndex);

        if (cartItemIndex > -1) {
            state.cartItems[cartItemIndex].quantity += 1;
        }
    },
    decrementCartQty(state, action: PayloadAction<number>) {
        let cartItemIndex = getCartItemIndexById(state, action.payload);
        // console.log(cartItemIndex)
        if (cartItemIndex > -1) {
            if (state.cartItems[cartItemIndex].quantity === 1) {
                state.cartItems.splice(cartItemIndex, 1);
            } else {
                state.cartItems[cartItemIndex].quantity -= 1;
            }
        }
    },
    emptyCart(state) {
        state.cartItems = [];
    },
    removeItemFromCart(state, action: PayloadAction<number>) {

        // let cartIndex = state.cartItems.findIndex(item => action.payload.menuId === item.menuId);

        // if (cartIndex > -1) {
            // state.cartItems[cartIndex] = action.payload;
            state.cartItems.splice(action.payload, 1);
        // }
    },
    toggleOpenCart(state){
        state.isOpen = !state.isOpen;
    }
  },
});


export const getCartItemIndexById = (state: ICartState, menuId: number) => {
    console.log(state.cartItems);

    let cartItemIndex = state.cartItems.findIndex(ci => ci.menuId === menuId);

    return cartItemIndex;
}

export const getCartCount = (state: ICartState, menuId: number) => {
    let cart = state.cartItems.find(ci => ci.menuId === menuId)

    if (cart) {
        return cart.quantity
    }

    return 0;
}

// export const { addItemToCart,updateCartItems, setSelectedBusinessMenuWithAddons, removeItemFromCart, emptyCart, incrementCartQty, decrementCartQty } = cartDataSlice.actions;
export const CartActions = cartDataSlice.actions;

export const selectCartData = (state: RootState) => state.cartData;

export default cartDataSlice.reducer;

