import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { appAxios } from "../../../network/axios";
import { register } from "../../../serviceWorker";
import { FullScreenLoader } from "../Loader/FullScreenLoader";
import { OtpPopup } from "./OtpPopup";

export const RegisterPopup: React.FC<{
  onClose: () => void;
}> = (props) => {
  const [loadingState, setLoadingState] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [userIdState, setUserIdState] = useState<number>();
  const [registerState, setRegisterState] = useState<{
    name: string;
    email: string;
    password: string;
    phone: string;
    referralCode: string;
  }>({
    name: "",
    email: "",
    password: "",
    phone: "",
    referralCode: "",
  });

  const [errorState, setErrorState] = useState<{
    name?: string;
    email?: string;
    password?: string;
    phone?: string;
  }>();

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRegisterState({
      ...registerState,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async (e: any) => {
    setLoadingState(true);

    if (e.preventDefault) e.preventDefault();
    try {
      const registerResponse = await appAxios.post(
        "/user/register",
        registerState
      );
      if (registerResponse.data?.user?.id) {
        toast.success("Registration Successful, An OTP has been sent to your mobile number.");
        setIsOtpSent(true);
        setUserIdState(registerResponse.data.user.id);
      } else {
        throw new Error(registerResponse.data.errorMessage);
      }
    } catch (error: any) {
      console.log(error.response);
      if (error.response.data.errors) {
        setErrorState(error.response.data.errors);
      }

      toast.error(error.response.data.message || error.message);
    }
    setLoadingState(false);
  };

  return (
    <>
      {loadingState && <FullScreenLoader />}
      {isOtpSent && userIdState ? (
        <OtpPopup userId={userIdState} onClose={props.onClose} />
      ) : (
        <Modal show onHide={props.onClose}>
          <Modal.Header closeButton>
            <Modal.Title>Register Now</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <form action="" method="POST" onSubmit={onSubmit}>
                <div className="form-group">
                  <label htmlFor="">Name</label>
                  <input
                    required
                    name="name"
                    type="text"
                    className="form-control"
                    onChange={onInputChange}
                  />
                  {errorState && errorState.name && (
                    <div className="text-danger">{errorState.name}</div>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="">Email</label>
                  <input
                    required
                    name="email"
                    type="email"
                    className="form-control"
                    onChange={onInputChange}
                  />
                  {errorState && errorState.email && (
                    <div className="text-danger">{errorState.email}</div>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="">Phone</label>
                  <input
                    name="phone"
                    required
                    type="text"
                    className="form-control"
                    onChange={onInputChange}
                  />
                  {errorState && errorState.phone && (
                    <div className="text-danger">{errorState.phone}</div>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="">Password</label>
                  <input
                    required
                    name="password"
                    type="password"
                    className="form-control"
                    onChange={onInputChange}
                  />
                  {errorState && errorState.password && (
                    <div className="text-danger">{errorState.password}</div>
                  )}
                </div>
                <div className="form-group mt-3">
                  <button className="btn btn-primary btn-md" onClick={onSubmit}>
                    <span>Register</span>
                  </button>
                </div>
              </form>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};
