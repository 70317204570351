import React from 'react'
import { Spinner } from 'react-bootstrap'

export const FullScreenLoader: React.FC = (props) => {

    return (
        <div className="fullscreen-loader">
            <Spinner animation="border" />
        </div>
    )
}
