import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TMenuList } from '../../../types/menuList';
import { RootState } from '../../store';
import { SiteDataService } from './siteData.service';

export interface IBusinessData {
  id: number;
  user_id: number;
  featured_img: string;
  name: string;
  domain: string;
  description: string;
  is_active: boolean;
  coming_soon: boolean;
  is_auto_accept: boolean;
  default_dish_minutes: number;
  manager_name: string;
  rating: number;
  commission_percentage: number;
  customer_commission_percentage: number;
  phone: string;
  email: string;
  business_address: string;
  latitude: string;
  longitude: string;
  city_id: number;
  mall_id: number;
  is_available: boolean;
  created_at: Date;
  updated_at: Date;
}

export interface ISiteDataState {
  loading: boolean;
  menuLoading: boolean;
  businessData: IBusinessData | null;
  menuData: Array<TMenuList>;
}

const initialState: ISiteDataState = {
  loading: true,
  businessData: null,
  menuLoading: false,
  menuData: []
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getSiteDataAsync = createAsyncThunk(
  'siteData/get',
  async () => {
    const response = await SiteDataService.getSiteDataApi();
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);
export const getRestaurantMenuAsync = createAsyncThunk(
  'restaurantMenu/get',
  async (businessId: number) => {
    const response = await SiteDataService.getRestaurantMenuApi(businessId);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const siteDataSlice = createSlice({
  name: 'siteData',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    // getSiteData: (state, action: PayloadAction<ISiteData>) => {
    //   state.siteData = action.payload;
    // },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder

      // Site Data Thunk
      .addCase(getSiteDataAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSiteDataAsync.fulfilled, (state, action) => {
        state.loading = false;
        console.log({ payload: action.payload });

        if (action.payload.success) {
          state.businessData = action.payload.result?.business;
        }
      })
      .addCase(getSiteDataAsync.rejected, (state) => {
        state.loading = false;
      })


      // Restaurant Menu thunk
      .addCase(getRestaurantMenuAsync.pending, (state) => {
        state.menuLoading = true;
      })
      .addCase(getRestaurantMenuAsync.fulfilled, (state, action) => {
        state.menuLoading = false;
        if (action.payload.menu) {
          state.menuData = action.payload.menu.filter((menuD: any) => menuD.menu_list.length > 0);
        }
      })
      .addCase(getRestaurantMenuAsync.rejected, (state) => {
        state.menuLoading = false;
      })

      ;
  },
});

// export const {  } = siteDataSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectSiteData = (state: RootState) => state.siteData;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount: number): AppThunk => (
//   dispatch,
//   getState
// ) => {
//   const currentValue = selectSiteData(getState());
//   if (currentValue) {
//     dispatch(incrementByAmount(amount));
//   }
// };

export default siteDataSlice.reducer;