import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { appAxios } from "../../../network/axios";
import { useAppDispatch } from "../../hooks/redux.hook";
import { authActions } from "../../slices/auth/auth.slice";
import { FullScreenLoader } from "../Loader/FullScreenLoader";
import { ForgotPasswordPopup } from "./ForgotPassword";

export const LoginPopup: React.FC<{
  onClose: () => void;
}> = (props) => {
  const [loginState, setLoginState] = useState<{
    email: string;
    password: string;
  }>({
    email: "",
    password: "",
  });

  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);
  const [loadingState, setLoadingState] = useState(false);

  const dispatch = useAppDispatch();

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoginState({
      ...loginState,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async (e: any) => {
    setLoadingState(true);

    if (e.preventDefault) e.preventDefault();
    try {
      const loginResponse = await appAxios.post("/user/login", loginState);
      if (loginResponse.data.user) {
        toast.success("Login Success.");
        dispatch(
          authActions.signin({
            authToken: loginResponse.data.accessToken,
            userId: loginResponse.data.user.id,
            userName: loginResponse.data.user.name,
          })
        );
        toast.success("Login Successful.");
        props.onClose();
      } else {
        throw new Error(loginResponse.data.errorMessage);
      }
    } catch (error: any) {
      toast.error(error.response.data.message || error.message);
    }
    setLoadingState(false);
  };

  return (
    <>
      {loadingState && <FullScreenLoader />}
      <Modal show onHide={props.onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Sign In</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form action="" onSubmit={onSubmit}>
              <div className="form-group">
                <label htmlFor="">Email</label>
                <input
                  required
                  name="email"
                  type="email"
                  className="form-control"
                  onChange={onInputChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="">Password</label>
                <input
                  required
                  name="password"
                  type="password"
                  className="form-control"
                  onChange={onInputChange}
                />
              </div>
              <div className="form-group mt-3">
                <div className="d-flex justify-content-between">
                  <button className="btn btn-primary">
                    <span>Login</span>
                  </button>
                  <button
                    className="btn btn-sm"
                    type="button"
                    onClick={() => {
                      dispatch(authActions.setForgotPopupOpen(true));
                      props.onClose();
                    }}
                  >
                    <span>Forgot Password ?</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
