import { getHostName } from "../../../helper";
import { appAxios } from "../../../network/axios";


export class SiteDataService {

    static getSiteDataApi() {
        const domain = getHostName(window.location.href);
        
        return appAxios.get("/get-site-data", {
            params: {
                domain
            }
        })
    }
    
    static getRestaurantMenuApi(businessId: number){
        
        return appAxios.get(`/user/get-restaurant-detail/${businessId}`)
    }
}