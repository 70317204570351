import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { appAxios } from "../../../network/axios";
import { useAppDispatch } from "../../hooks/redux.hook";
import { authActions } from "../../slices/auth/auth.slice";
import { FullScreenLoader } from "../Loader/FullScreenLoader";
import { OtpPopup } from "./OtpPopup";

export const ForgotPasswordPopup: React.FC<{
  onClose: () => void;
}> = (props) => {
  const [phoneState, setPhoneState] = useState<string>("");

  const [loadingState, setLoadingState] = useState(false);

  const [otpSentState, setOtpSentState] = useState(false);
  const [otpVerifiedState, setOtpVerifiedState] = useState(false);
  const [otpInputState, setOtpInputState] = useState("");
  const [resetPassToken, setResetPassToken] = useState("");
  const [inputPassword, setInputPassword] = useState("");

  const dispatch = useAppDispatch();

  const onSubmit = async (e: any) => {
    setLoadingState(true);

    if (e.preventDefault) e.preventDefault();
    if (!otpSentState) {
      try {
        const loginResponse = await appAxios.post("/user/forgot-password", {
          phone: phoneState,
        });
        if (loginResponse.data.success) {
          toast.success("OTP has been sent to your email.");
          setOtpSentState(true);
        } else {
          throw new Error(loginResponse.data.errorMessage);
        }
      } catch (error: any) {
        toast.error(error.response.data.message || error.message);
      }
    } else {
      try {
        const loginResponse = await appAxios.post("/user/verify-otp", {
          phone: phoneState,
          otp: otpInputState,
        });
        if (loginResponse.data.success) {
          setResetPassToken(loginResponse.data.reset_token);
          toast.success("OTP verified successfully.");
          setOtpVerifiedState(true);
        } else {
          throw new Error(loginResponse.data.errorMessage);
        }
      } catch (error: any) {
        toast.error(error.response.data.message || error.message);
      }
    }
    setLoadingState(false);
  };

  const changePasswordSubmit = async (e: any) => {
    e.preventDefault();
    setLoadingState(true);
    try {
      const loginResponse = await appAxios.post(
        "/user/forgot-password-change",
        {
          phone: phoneState,
          resetToken: resetPassToken,
          password: inputPassword,
        }
      );
      if (loginResponse.data.success) {
        toast.success("Password changed successfully.");
        setOtpSentState(false);
        setOtpVerifiedState(false);
        setOtpInputState("");
        dispatch(authActions.setForgotPopupOpen(false));
      } else {
        throw new Error(loginResponse.data.errorMessage);
      }
    } catch (error: any) {
      toast.error(error.response.data.message || error.message);
    }
    setLoadingState(false);
  };

  return (
    <>
      {loadingState && <FullScreenLoader />}
      <Modal show onHide={props.onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Forgot Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {otpVerifiedState ? (
              // ChANGE PASSWORD FORM
              <form action="" onSubmit={changePasswordSubmit}>
                <div className="form-group">
                  <label htmlFor="">New Password</label>
                  <input
                    required
                    name="password"
                    type="password"
                    className="form-control"
                    onChange={(e) => {
                      setInputPassword(e.target.value);
                    }}
                  />
                </div>
                <div className="form-group mt-3">
                  <div className="d-flex justify-content-between">
                    <button className="btn btn-primary">
                      <span>Change Password</span>
                    </button>
                  </div>
                </div>
              </form>
            ) : (
              // OTP SCREEN
              <form action="" onSubmit={onSubmit}>
                <div className="form-group">
                  <label htmlFor="">Phone</label>
                  <input
                    required
                    name="phone"
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      setPhoneState(e.target.value);
                    }}
                  />
                </div>
                {otpSentState && (
                  <div className="form-group">
                    <label htmlFor="">OTP</label>
                    <input
                      required
                      name="otp"
                      type="text"
                      className="form-control"
                      onChange={(e) => {
                        setOtpInputState(e.target.value);
                      }}
                    />
                  </div>
                )}
                <div className="form-group mt-3">
                  <div className="d-flex justify-content-between">
                    <button className="btn btn-primary">
                      <span>Submit</span>
                    </button>
                  </div>
                </div>
              </form>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
