// export const APP_URL = "http://localhost:8080";
export const APP_URL = "https://web3order.com";
export const APP_VERSION = "1.4.3";
export const MENU_FEATURED_URL = APP_URL + "/storage/uploads/menu";
export const RESTAURANT_FEATURED_URL = APP_URL + "/storage/uploads/business";
export const BACKEND_TIMEZONE = "America/Los_Angeles";


export const ORDER_STATUSES = {
    1: 'WAITING', //just ordered by the user
    2: 'NO RESPONSE', //when no response from vendor
    3: 'REJECTED', // vendor has rejected the order
    4: 'PREPARING',// vendor has accepted the order and preparing
    5: 'CANCELLED', // user has cancelled the order
    6: 'RESTAURANT CANCELLED', // vendor has cancelled the order after accepting
    7: 'PAID', // user has paid for the order
    8: 'PREPEARING', // vendor is preparing order
    9: 'READY', // Order is ready (when request sent by vendor)
    10: 'DELIVERED' // Order delivered  (when pressed okay after vibration by user)
}