import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { appAxios } from "../../../network/axios";
import { IAuthState, IAuthToken } from "../../types/auth";

const initialState: IAuthState = {
  authToken: null,
  isPending: false,
  userName: "",
  userId: null,
  isLoggedIn: false,
  source: null,
  isForgotPopupOpen: false,
};

const saveTokenDataToLocalStorage = (authToken: IAuthToken) => {
  if (authToken.access_token) {
    // authToken.expires_in is in seconds

    // let expiry_time = new Date().getTime() + (authToken.expires_in * 1000);

    localStorage.setItem("auth_token", authToken.access_token);
    localStorage.setItem("token_expiry", authToken.expires_in.toString());
    localStorage.setItem("refresh_token", authToken.refresh_token);
  }
};
export const removeTokenFromStore = () => {
  localStorage.removeItem("auth_token");
  localStorage.removeItem("token_expiry");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("user_data");
};

export const logout = createAsyncThunk("auth/logout", async (_, thunkApi) => {
  let logoutResponse;
  try {
    logoutResponse = await appAxios
      .post("/user/logout", {
        expo_token: "s",
      })
      .then((res) => {
        return res.data;
      });
  } catch (error) {
    return false;
  }
  return logoutResponse;
});

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    // addTodo(state: Array<any>, action: PayloadAction<any>) {
    //   const { id, text } = action.payload
    //   state.push({ id, text, completed: false })
    // },
    signin(state, action: PayloadAction<IAuthState>) {
      const { authToken, userName, userId } = action.payload;

      if (authToken) {
        if (action.payload.source === "SECURE_STORE") {
          authToken.expires_in = authToken.expires_in;
        } else {
          authToken.expires_in =
            new Date().getTime() + authToken.expires_in * 1000;
        }
      }

      state.authToken = authToken;
      if (userId && userName) {
        state.userName = userName;
        state.userId = userId;
        localStorage.setItem(
          "user_data",
          JSON.stringify({
            id: userId,
            name: userName,
          })
        );
      }
      if (authToken) {
        state.isLoggedIn = true;
        saveTokenDataToLocalStorage(authToken);
      }
    },
    refreshToken(state, action: PayloadAction<IAuthToken>) {
      const authToken = action.payload;
      if (authToken) {
        saveTokenDataToLocalStorage(authToken);
      }
    },
    setForgotPopupOpen(state, action: PayloadAction<boolean>) {
      state.isForgotPopupOpen = action.payload;
    },
    removeTokenIfRefreshFailed(state) {
      state.authToken = null;
      state.userId = null;
      state.userName = "";
      state.isLoggedIn = false;
      removeTokenFromStore();
    },
    // logout(state) {
    //   // const { authToken, userName, userId } = action.payload
    //   // state = {...initialState};
    //   // return
    //   let logoutMsg = "";
    //   appAxios.post("/user/logout").then(async res => {
    //     logoutMsg = res.data.success
    //   }).catch(err => {
    //     console.log(err);
    //   })

    //   state.authToken = null;
    //   state.userId = null;
    //   state.userName = "";
    //   state.isLoggedIn = false;
    //   // setAuthToken(null);
    //   SecureStore.deleteItemAsync('auth_token');
    //   SecureStore.deleteItemAsync('token_expiry');
    //   SecureStore.deleteItemAsync('refresh_token');
    //   SecureStore.deleteItemAsync('user_data');
    // },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(logout.fulfilled, (state, action) => {
        state.authToken = null;
        state.userId = null;
        state.userName = "";
        state.isLoggedIn = false;
        // removeTokenFromStore()
      })
      .addCase(logout.rejected, (state, action) => {
        state.authToken = null;
        state.userId = null;
        state.userName = "";
        state.isLoggedIn = false;
        // removeTokenFromStore()
      })
      .addCase(logout.pending, (state, action) => {
        state.isPending = true;
      });
  },
  // extraReducers: (builder) => {
  // builder.addCase(logout.fulfilled, (state, action) => {
  //   state.authToken = null;
  //   state.userId = null;
  //   state.userName = "";
  //   state.isLoggedIn = false;
  //   // removeTokenFromStore()
  // }),
  // builder.addCase(logout.rejected, (state, action) => {
  //   state.authToken = null;
  //   state.userId = null;
  //   state.userName = "";
  //   state.isLoggedIn = false;
  //   // removeTokenFromStore()
  // })
  // },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
