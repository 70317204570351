import React, { useEffect, useMemo, useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { FullScreenLoader } from "../Loader/FullScreenLoader";
import { toast } from "react-toastify";
import { PaymentMethod, PaymentMethodCreateParams } from "@stripe/stripe-js";
import { appAxios } from "../../../network/axios";
import { useAppDispatch, useAppSelector } from "../../hooks/redux.hook";
import {
  CartActions,
  selectCartData,
} from "../../slices/cartData/cartData.slice";
import { selectSiteData } from "../../slices/siteData/siteData.slice";
import { useHistory } from "react-router-dom";

const useOptions = () => {
  const fontSize = "14";
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    [fontSize]
  );

  return options;
};

const CardForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  const cartState = useAppSelector(selectCartData);
  const siteState = useAppSelector(selectSiteData);

  const history = useHistory();

  const dispatch = useAppDispatch();

  const authData = useAppSelector((state) => state.authData);

  const [loadingState, setLoadingState] = useState(false);

  const [billingState, setBillingState] =
    useState<PaymentMethodCreateParams.BillingDetails>();

  const createOrder = async (paymentMethod: PaymentMethod | undefined) => {
    if (paymentMethod && siteState.businessData && paymentMethod.id) {
      const guestPaymentRes = await appAxios.post("/make-guest-payment", {
        name: paymentMethod.billing_details.name,
        email: paymentMethod.billing_details.email,
        phone: paymentMethod.billing_details.phone,
        paymentId: paymentMethod.id,
        menus: cartState.cartItems,
        businessId: siteState.businessData.id,
        userId: authData.userId,
      });

      const guestPayment = guestPaymentRes.data;

      if (guestPayment.success && guestPayment.payment_success) {
        dispatch(CartActions.emptyCart());
        toast.success(
          "Order Successfully placed. Order Id: " + guestPayment.data.orderId
        );
        history.replace("/");
      } else {
        toast.error(
          guestPayment.data.error || "Payment Failed, please try again."
        );
      }
    } else {
      toast.error("Payment Failed, Please try again.");
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBillingState({
      ...billingState,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (event: any) => {
    setLoadingState(true);
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (cardElement) {
      const payload = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: billingState,
      });

      const order = await createOrder(payload.paymentMethod);

      console.log("[PaymentMethod]", payload);
    }
    setLoadingState(false);
  };

  // useEffect(() => {÷

  return (
    <>
      {loadingState && <FullScreenLoader />}
      <h5 className="mb-4">Make Payment</h5>
      <form onSubmit={handleSubmit}>
        <label className="mb-2">Card Details</label>
        <div className="form-group mb-4">
          <label className="label w-100">
            <CardElement
              options={options}
              onReady={() => {
                console.log("CardElement [ready]");
              }}
              onChange={(event) => {
                console.log("CardElement [change]", event);
              }}
              onBlur={() => {
                console.log("CardElement [blur]");
              }}
              onFocus={() => {
                console.log("CardElement [focus]");
              }}
            />
          </label>
        </div>
        {!authData.isLoggedIn && (
          <>
            <label>Billing Details</label>
            <div className="form-group my-2">
              <input
                onChange={handleInputChange}
                className="form-control"
                required
                name="name"
                placeholder="Name"
              />
            </div>
            <div className="form-group my-2">
              <input
                onChange={handleInputChange}
                className="form-control"
                type="email"
                required
                name="email"
                placeholder="Email"
              />
            </div>
            <div className="form-group my-2">
              <input
                onChange={handleInputChange}
                className="form-control"
                required
                type="tel"
                name="phone"
                placeholder="Phone"
              />
            </div>
          </>
        )}
        <div className="form-group  mt-2">
          <button className="btn btn-primary" type="submit" disabled={!stripe}>
            Pay Now
          </button>
        </div>
      </form>
    </>
  );
};

export default CardForm;
