import React from 'react'
import { useAppDispatch } from '../hooks/redux.hook';
import { CartActions } from '../slices/cartData/cartData.slice';
// import {  } from "module";

interface ICartBarProps {
    amount: number;
    // count: number;
}

export const CartBar: React.FC<ICartBarProps> = (props) => {

    const dispatch = useAppDispatch();


    return (
        <div style={{

            position: "fixed",
            bottom: 0,
            left: 0,
            width: '100%',
            // paddingHorizontal: 0,

        }}>
            <div className="bg-primary container" style={{
                // backgroundColor: '#1ec71e',
                // borderRadius: 8,
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingTop: 10,
                paddingBottom: 10,
                // paddingRight: 10,
                display: "flex",
            }}>

                <p className="mb-0" style={{
                    color: '#fff',
                }}>
                    Amount: {props.amount} CAD
                </p>
                <button className="btn btn-light" onClick={() => {
                    // alert('d')
                    // navigation.push("Checkout")
                    dispatch(CartActions.toggleOpenCart())
                }}>
                   View Cart
                </button>
            </div>
        </div>
    )
}
