import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TMenuList } from "../../../types/menuList";
import { RootState } from "../../store";

export interface ICommonSlice {
  isLoading: boolean;
}

const initialState: ICommonSlice = {
  isLoading: false,
};

export const commonSlice = createSlice({
  name: "common",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    // getSiteData: (state, action: PayloadAction<ISiteData>) => {
    //   state.siteData = action.payload;
    // },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

// export const {  } = siteDataSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCommonData = (state: RootState) => state.common;
export const CommonActions = commonSlice.actions;
// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount: number): AppThunk => (
//   dispatch,
//   getState
// ) => {
//   const currentValue = selectSiteData(getState());
//   if (currentValue) {
//     dispatch(incrementByAmount(amount));
//   }
// };

export default commonSlice.reducer;
