import axios from "axios";
import { removeTokenFromStore } from "../app/slices/auth/auth.slice";
import { APP_URL } from "../Env";

const getAuthToken = () => {
  const token = localStorage.getItem("auth_token");
  return token;
};

// Axios instance
const Axios = axios.create({
  baseURL: `${APP_URL}/api/v1`,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    // Authorization: getAuthToken() ? "Bearer: " + getAuthToken() : undefined,
    crossDomain: true,
  },
});

Axios.interceptors.request.use(
    (config) => {
        const token = getAuthToken();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

Axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response.status === 401) {
            removeTokenFromStore();            // window.location.href = "/login";
        }
        return Promise.reject(error);
    }
);

export const appAxios = Axios;
