import React from "react";
import { useAppSelector } from "../../hooks/redux.hook";
import { selectCommonData } from "../../slices/common/common.slice";
import { FullScreenLoader } from "./FullScreenLoader";

export const ReduxLoader: React.FC<{}> = () => {
  const commonData = useAppSelector(selectCommonData);

  return commonData.isLoading ? <FullScreenLoader /> : null;
};
