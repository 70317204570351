import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { appAxios } from "../../../network/axios";
import { useAppDispatch } from "../../hooks/redux.hook";
import { authActions } from "../../slices/auth/auth.slice";
import { FullScreenLoader } from "../Loader/FullScreenLoader";

export const OtpPopup: React.FC<{ onClose: () => void; userId: number; }> = (
  props
) => {
  const [loadingState, setLoadingState] = useState(false);

  const dispatch = useAppDispatch();
  const [otpState, setOtpState] = useState({
    otp: "",
    userId: -1,
  });


  useEffect(() => {
    if(props.userId){
        setOtpState({
            ...otpState,
            userId: props.userId
        })
    }
  }, [props.userId])
  

  const onSubmit = async (e: any) => {
    setLoadingState(true);

    if (e.preventDefault) e.preventDefault();
    try {
      const registerResponse = await appAxios.post(
        "/user/register-verify-otp",
        otpState
      );
      if (registerResponse.data.success) {
        toast.success("OTP Verified Successfully.");
        dispatch(authActions.signin({
          authToken: registerResponse.data.access_token,
          userId: registerResponse.data.user.id,
          userName: registerResponse.data.user.name
        }))
        props.onClose();
      } else {
        throw new Error(registerResponse.data.errorMessage);
      }
    } catch (error: any) {
      toast.error(error.response.data.message || error.message);
    }
    setLoadingState(false);
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOtpState({
      ...otpState,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      {loadingState && <FullScreenLoader />}
      <Modal show onHide={props.onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Enter OTP</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form action="" method="POST" onSubmit={onSubmit}>
              <div className="form-group">
                <label htmlFor="">Enter OTP</label>
                <input
                  required
                  name="otp"
                  type="text"
                  className="form-control"
                  onChange={onInputChange}
                />
              </div>

              
              <div className="form-group mt-3">
                <button className="btn btn-primary btn-md" onClick={onSubmit}>
                  <span>Verify OTP</span>
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
