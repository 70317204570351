import React, { useEffect, useState } from "react";
import { ListGroup } from "react-bootstrap";
import { MENU_FEATURED_URL } from "../../../Env";
import { MenuAddonModal } from "../../components/Menu/MenuAddonModal";
import { selectSiteData } from "../../slices/siteData/siteData.slice";
import { useAppDispatch, useAppSelector } from "../../hooks/redux.hook";
import {
  CartActions,
  selectCartData,
} from "../../slices/cartData/cartData.slice";
import { IAddonCategory } from "../../../types/menuList";
import { CartBar } from "../../components/CartBar";
import { calculateAmount } from "../../../utils/calculateAmount";
import { CartModal } from "../../components/Cart/CartPopup";
import banner from "../../assets/banner.png";
import { MenuItem } from "../../components/Menu/MenuItem";

export const RestaurantMenuScreen: React.FC = () => {
  const siteData = useAppSelector(selectSiteData);
  const cartData = useAppSelector(selectCartData);

  const [selectedMenuIdState, setSelectedMenuIdState] = useState<number | null>(
    null
  );

  const [activeMenuState, setActiveMenuState] = useState(-1);

  // const [selectedCartIndexState, setSelectedCartIndexState] = useState<number>();
  const [menuAddonList, setMenuAddonList] = useState<Array<IAddonCategory>>([]);

  const [addonOpenState, setAddonOpenState] = useState(false);
  // const [isCartOpenState, setIsCartOpenState] = useState(false)
  // const [menuDetailState, setMenuDetailState] = useState<TMenuList[]>([]);
  const [cartAmountState, setCartAmountState] = useState<number>(0);

  const dispatch = useAppDispatch();

  useEffect(() => {
    // console.log(siteData.menuData)
    dispatch(CartActions.setSelectedBusinessMenuWithAddons(siteData.menuData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteData.menuData]);

  useEffect(() => {
    if (siteData.menuData) {
      let amount = calculateAmount(
        cartData.selectedBusinessMenu,
        cartData.cartItems
      );
      setCartAmountState(parseFloat(amount.toFixed(2)));
    }

    return () => {
      // cleanup
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartData.cartItems]);

  const openAddonWindow = () => {
    // if (cartState.cartItems.length === 0) {
    //     selectBusinessInCartSlice();
    // }
    setAddonOpenState(true);
    // modalizeRef.current?.open();
  };

  const handleAddClick = (menuId: number) => {
    if (siteData.menuData) {
      siteData.menuData.forEach((menuCat) => {
        let selectedMenu = menuCat.menu_list.find((menu) => {
          return menu.id === menuId;
        });
        if (selectedMenu) {
          setSelectedMenuIdState(selectedMenu.id);
          setMenuAddonList(selectedMenu.addon_categories);

          openAddonWindow();
          return;
        }
      });
    }
  };

  return (
    <div
      style={{
        // marginTop: 70,
        background: "#eee",
      }}
    >
      {addonOpenState && (
        <MenuAddonModal
          selectedMenuId={selectedMenuIdState}
          addonsList={menuAddonList}
          onClose={() => setAddonOpenState(false)}
        />
      )}
      {cartData.isOpen && (
        <CartModal onClose={() => dispatch(CartActions.toggleOpenCart())} />
      )}
      <div>
        <img src={banner} className="img-fluid" alt="" />
      </div>
      <div className="container mb-5 ">
        <div className="d-sm-none">
          <div className="row justify-content-center mt-2">
            <div className="col-12">
              <select value={activeMenuState} className="form-control" onChange={(e) => {
                setActiveMenuState(parseInt(e.target.value))
              }}>
                <option value={-1}>All</option>
                {siteData.menuData &&
                  siteData.menuData.map((menuCat, index) => {
                    return (
                      <option key={index} value={index}>
                        {menuCat.category_name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
        </div>
        <div className="row d-none d-sm-block">
          <div className="col-md-12 category-name-list">
            <span
              onClick={() => {
                setActiveMenuState(-1);
              }}
              className={`category-name-item cursor-pointer ${
                activeMenuState === -1 ? "active" : ""
              }`}
            >
              All
            </span>
            {siteData.menuData.map((menu, index) => (
              <span
                onClick={() => {
                  setActiveMenuState(index);
                }}
                className={`category-name-item cursor-pointer ${
                  activeMenuState === index ? "active" : ""
                }`}
              >
                {menu.category_name}
              </span>
            ))}
          </div>
        </div>
        <div className="row">
          {activeMenuState > -1
            ? siteData.menuData[activeMenuState]?.menu_list.map((menuItem) => (
                <MenuItem onAdd={handleAddClick} menuDetail={menuItem} />
              ))
            : siteData.menuData.map((menu) =>
                menu.menu_list.map((menuItem) => (
                  <MenuItem onAdd={handleAddClick} menuDetail={menuItem} />
                ))
              )}

          {/* </ListGroup> */}
        </div>
      </div>
      {
        // cartState.cartItems.length > 0 && !selectedMenuIdState && (
        cartData.cartItems.length > 0 && <CartBar amount={cartAmountState} />
      }
    </div>
  );
};
